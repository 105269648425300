<template>
  <div class="formulas-table-row row">
    <div class="row-left">
      <img src="@/assets/svg/icon-delete-admin.svg" alt="" @click="showAlert = true"/>
      <img src="@/assets/images/check-active.png" alt="" @click="addNewParams" />
      <ui-select :placeholder="$t('not-selected')" v-model="selectVal" :options="options"/>
    </div>
    <div class="row-right">
      <ui-select v-model="mathVal" :options="mathOptions"/>
    </div>
    <UiAlert v-model="showAlert">
      <div class="ui-alert__title">{{ $t('do-you-really-want-to-delete') }}</div>
      <div class="ui-alert__action">
        <ui-button @click="$emit('deleteNewParam')" color="error">{{ $t('delete') }}</ui-button>
        <ui-button @click="showAlert = false" color="white">{{ $t('cancel-2') }}</ui-button>
      </div>
    </UiAlert>
  </div>
</template>

<script>
import UiSelect from "@/components/ui/UiSelect.vue";
import UiAlert from "@/components/ui/UiAlert.vue";
import UiButton from "@/components/ui/UiButton.vue";

export default {
  name: "ParamsTableNewRow",
  components: {UiButton, UiAlert, UiSelect},
  props: {
    options: {
      type: Array,
      default: () => []
    },
    ord: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      showAlert: false,
      selectVal: {},
      mathVal: {},
      mathOptions: [
        {name: this.$t('add-math'), type: 'Add'},
        {name: this.$t('subtract'), type: 'Subtract'},
        {name: this.$t('multiply'), type: 'Multiply'},
        {name: this.$t('divide'), type: 'Divide'},
      ]
    }
  },

  mounted () {
    this.selectVal = this.options[0];
    this.mathVal = this.mathOptions[0];
  },

  methods: {
    addNewParams() {
      let data = {
        rrParmSpecId: this.selectVal.id,
        action: this.mathVal.type,
        ord: this.ord + 1
      }
      this.$emit('addNewParam', data)
    }
  }
}
</script>

<style lang="scss" scoped>
.row {
  position: relative;

  &-left {
    display: flex;
    align-items: center;
    gap: 10px;

    img {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }

    ::v-deep .ui-select {
      max-width: 370px;

      &__inner {
        font-size: 10px;
        font-family: "Gotham Pro Regular";
      }
    }
  }

  &-right {
    position: absolute;
    width: 100%;
    left: 450px;
    top: 30px;
    max-width: 193px;
    display: flex;
    align-items: center;
    gap: 5px;

    ::v-deep .ui-select {
      max-width: 153px;
    }

    img {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }
}
</style>