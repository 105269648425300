<template>
  <div class="formulas-table-row row">
    <div class="row-left">
      <img src="@/assets/svg/icon-delete-admin.svg" alt="" @click="showAlert = true"/>
      <img src="@/assets/images/check-active.png" alt="" v-if="isEdit" @click="editParam"/>
      <ui-select :placeholder="$t('not-selected')" v-model="paramsValue" :options="options" @input="editAction"/>
    </div>
    <div class="row-right">
      <ui-select v-model="mathVal" :options="mathOptions" v-if="!showMath" @input="editAction" />
    </div>
    <UiAlert v-model="showAlert">
      <div class="ui-alert__title">{{ $t('do-you-really-want-to-delete') }}</div>
      <div class="ui-alert__action">
        <ui-button @click="$emit('deleteParam', item)" color="error">{{ $t('delete') }}</ui-button>
        <ui-button @click="showAlert = false" color="white">{{ $t('cancel-2') }}</ui-button>
      </div>
    </UiAlert>
  </div>
</template>

<script>
import UiSelect from "@/components/ui/UiSelect.vue";
import UiAlert from "@/components/ui/UiAlert.vue";
import UiButton from "@/components/ui/UiButton.vue";

export default {
  name: "ParamsTableRow",
  components: {UiButton, UiAlert, UiSelect},
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    options: {
      type: Array,
      default: () => []
    },
    newRow: {
      type: Boolean,
      default: false
    },
    showMath: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      paramsValue: {},
      mathVal: {},
      mathOptions: [
        {name: this.$t('add-math'), type: 'Add'},
        {name: this.$t('subtract'), type: 'Subtract'},
        {name: this.$t('multiply'), type: 'Multiply'},
        {name: this.$t('divide'), type: 'Divide'},
      ],
      isEdit: false,
      showAlert: false
    }
  },

  mounted () {
    this.options.forEach(el => {
      if (el.id === this.item.rrParmSpecId) {
        this.paramsValue = el
      }
    })
    this.mathOptions.forEach(el => {
      if(el.type === this.item.action) {
        this.mathVal = el
      }
    })
  },

  methods: {
    editAction() {
      if (this.paramsValue.id !== this.item.rrParmSpecId || this.mathVal.type !== this.item.action) {
        this.isEdit = true
      }else {
        this.isEdit = false
      }
    },

    editParam() {
      let data = {
        rrParmSpecId: this.paramsValue.id,
        action: this.mathVal.type,
        ord: this.item.ord,
        itemId: this.item.id
      }
      this.$emit('editParam', data)
    }
  }
}
</script>

<style lang="scss" scoped>
.row {
  position: relative;

  &-left {
    display: flex;
    align-items: center;
    gap: 10px;

    img {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }

    ::v-deep .ui-select {
      max-width: 400px;

      &__inner {
        font-size: 10px;
        font-family: "Gotham Pro Regular";
      }
    }
  }

  &-right {
    position: absolute;
    width: 100%;
    left: 450px;
    top: 30px;
    max-width: 193px;
    display: flex;
    align-items: center;
    gap: 5px;

    ::v-deep .ui-select {
      max-width: 153px;
    }

    img {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }
}
</style>