<template>
  <div class="final">
    <div class="final__select">
      <div class="final__select-item">
        <p>{{ $t('year') }}</p>
        <ui-select v-model="year" :options="getYears" @input="getTypes"/>
      </div>
      <div class="final__select-item">
        <p>{{ $t('type-2') }}</p>
        <ui-select v-model="type" :options="getRegionTypes" @input="getSubtypes"/>
      </div>
      <div class="final__select-item">
        <p>{{ $t('subtype') }}</p>
        <ui-select v-model="subtype" :options="getRegionSubtypes" @input="getRegionGroups"/>
      </div>
      <div class="final__select-item">
        <p>{{ $t('group') }}</p>
        <ui-select v-model="group" :options="getGroups" @input="getFinalIndicators"/>
      </div>
    </div>
    <UiLoader v-model="loader" />
    <UiNotification v-model="showNotification" :message="message" />
    <Table
        v-if="(group.name && !loader && getIndicators.length) || isEmpty"
        :title="$t('groups-of-indicators')"
        :descriptionList="[
            {title: $t('code'), width: 50},
            {title: $t('type-2'), width: 420},
            {title: $t('federal-district-russian-federation-calculation'), width: 97, paddingLeft: 0, textAlign: 'center'},
            {title: $t('indicator-weight'), width: 111, paddingLeft: 0, textAlign: 'center'},
            {title: $t('algorithm-calculation'), width: 120, paddingLeft: 0, textAlign: 'center'},
            {title: $t('inversion'), width: 100, paddingLeft: 0, textAlign: 'center'}
        ]"
        :actionText="$t('add-line')"
        :data="getIndicators"
        :sort="true"
        hideEditIcon
        ref="table"
        isFinal
        @addRowField="addFinalIndicators"
        @changeRowField="editFinalIndicators"
        @deleteRowField="deleteFinalIndicators"
    />
    <template v-if="(group.name && !loader && getIndicators.length) || isEmpty">
      <ParamsTable
          v-for="(item, key) in dataForParams"
          :data="item"
          :key="key"
          :options="paramsOptions"
          @addNewParam="addNewParam"
          @editParam="editParam"
          @deleteParam="deleteParam"
          @deleteAllParams="deleteAllParams"
      />
    </template>
    <ParamsTable
        is-new
        v-if="isNewParamsTable && !loader"
        :addParamsTableOption="forNewParams"
        :options="paramsOptions"
        @deleteNewParam="isNewParamsTable = false"
        @addNewParam="addNewParam"
    />
    <div class="final__actions">
      <ui-button v-if="group.name && !loader && !getIndicators.length && !isEmpty" color="error" @click="addNewTable">
        <img src="@/assets/svg/icon-plus-white.svg" alt="">
        {{ $t('new-table') }}
      </ui-button>
    </div>
    <div class="final__actions">
      <ui-button v-if="group.name && !loader && getIndicators.length && forNewParams.length" color="error" @click="addNewParams">
        <img src="@/assets/svg/icon-plus-white.svg" alt="">
        {{ $t('add-parameters') }}
      </ui-button>
    </div>
  </div>
</template>

<script>
import UiSelect from "@/components/ui/UiSelect.vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import UiLoader from "@/components/ui/UiLoader.vue";
import UiNotification from "@/components/ui/UiNotification.vue";
import UiButton from "@/components/ui/UiButton.vue";
import Table from "@/components/ui/UiTable.vue";
import ParamsTable from "@/components/template/admin/regionInformer/ParamsTable.vue";

export default {
  name: "Final",
  components: {ParamsTable, Table, UiButton, UiNotification, UiLoader, UiSelect},

  data () {
    return {
      year: {},
      type: {},
      subtype: {},
      group: {},
      loader: false,
      showNotification: false,
      message: '',
      isEmpty: false,
      isNewParamsTable: false,
      forNewParams: [],
      dataForParams: [],
      paramsOptions: []
    }
  },

  computed: {
    ...mapGetters(['getRegionTypes', 'getRegionSubtypes', 'getGroups', "getIndicators", 'getParamsOptions', 'getYears']),
  },

  methods: {
    ...mapActions([
      'years',
      'regionTypes',
      'regionSubtypes',
      'groups',
      'indicators',
      'addIndicators',
      'editIndicators',
      'deleteIndicators'
    ]),
    ...mapMutations(['setRegionTypes', 'setRegionSubtypes', 'setGroups', 'setYears']),

    loadPage(message) {
      this.loader = true
      this.isNewParamsTable = false
      this.dataForParams = []
      this.forNewParams = []
      const payload = {
        type: 'Calculated',
        rrSpecBlockId: null,
        year: this.year.value,
        rrGroupId: this.group.id,
      }
      this.indicators(payload).then(() => {
        this.getIndicators.forEach(el => {
          if(!el.params.length) {
            const data = {
              name: el.code,
              id: el.id
            }
            this.forNewParams.push(data)
          } else {
            const data = {
              title: el.code,
              items: el.params,
              id: el.id,
            }
            this.dataForParams.push(data)
          }
        })
        this.indicators({year: this.year.value}).then(() => {
          this.paramsOptions = this.getParamsOptions
          setTimeout(() => {
            this.showNotification = true
            this.loader = false;
            this.message = message
          }, 500)
        })
      })
    },

    getTypes () {
      this.type = {};
      this.subtype = {};
      this.group = {};
      this.regionTypes(this.year.value)
      this.setRegionSubtypes([])
      this.setGroups([])
    },

    getSubtypes () {
      this.subtype = {};
      this.group = {};
      this.regionSubtypes({typeId: this.type.id})
      this.setGroups([])
    },

    getRegionGroups () {
      this.group = {};
      this.groups({subtypeId: this.subtype.id})
    },

    getFinalIndicators () {
      this.loader = true
      this.isNewParamsTable = false
      this.dataForParams = []
      this.forNewParams = []
      const payload = {
        type: 'Calculated',
        rrSpecBlockId: null,
        year: this.year.value,
        rrGroupId: this.group.id,
      }
      this.indicators(payload).then(() => {
        this.getIndicators.forEach(el => {
          if(!el.params.length) {
            const data = {
              name: el.code,
              id: el.id
            }
            this.forNewParams.push(data)
          } else {
            const data = {
              title: el.code,
              items: el.params,
              id: el.id,
            }
            this.dataForParams.push(data)
          }
        })
        this.indicators({year: this.year.value}).then(() => {
          this.paramsOptions = this.getParamsOptions
          this.loader = false
        })
      })
    },

    addNewTable() {
      this.isEmpty = true
      setTimeout(() => {
        this.$refs.table.addItem();
      }, 100)
    },

    addFinalIndicators (data) {
      this.loader = true;
      this.isEmpty = false;
      const payload = {
        type: 'Calculated',
        year: this.year.value,
        name: data.name,
        ord: +data.ord,
        code: data.code,
        ratio: data.ratio,
        rrGroupId: this.group.id,
        algorithmId: data.algorithmId,
        isInversion: data.isInversion,
        isFederalDistrictBased: data.isFederalDistrictBased
      }
      this.addIndicators(payload).then(() => {
        this.loadPage(this.$t('type-successfully-added'))
      })
    },

    editFinalIndicators(data) {
      console.log(data)
      this.loader = true;
      const payload = {
        type: 'Calculated',
        id: data.id,
        year: this.year.value,
        name: data.name,
        ord: +data.ord,
        code: data.code,
        ratio: data.ratio,
        algorithmId: data.algorithmId,
        isInversion: data.isInversion,
        isFederalDistrictBased: data.isFederalDistrictBased,
        params: data.params
      }
      this.editIndicators(payload).then(() => {
        this.loadPage(this.$t('type-successfully-updated'))
      })
    },

    deleteFinalIndicators(data) {
      this.loader = true
      this.deleteIndicators(data).then(() => {
        this.loadPage(this.$t('type-successfully-deleted'))
      })
    },

    addNewParams () {
      this.isNewParamsTable = true
    },

    addNewParam (newParams) {
      this.loader = true;
      let indicator = this.getIndicators.find(indicator => indicator.id === newParams.itemId)
      indicator.params.push(newParams.data)
      this.editIndicators(indicator).then(() => {
        this.loadPage(this.$t('parameter-successfully-added'))
      })
    },

    deleteAllParams (id) {
      this.loader = true;
      let indicator = this.getIndicators.find(indicator => indicator.id === id)
      indicator.params = []
      this.editIndicators(indicator).then(() => {
        this.loadPage(this.$t('parameter-successfully-updated'))
      })
    },

    deleteParam (param) {
      this.loader = true;
      let indicator = this.getIndicators.find(indicator => indicator.id === param.itemId)
      indicator.params = indicator.params.filter(el => el.id !== param.data.id)
      this.editIndicators(indicator).then(() => {
        this.loadPage(this.$t('parameter-successfully-deleted'))
      })
    },

    editParam (param) {
      this.loader = true;
      let indicator = this.getIndicators.find(indicator => indicator.id === param.rowId)
      indicator.params = indicator.params.map(el => {
        if(el.id === param.data.itemId) {
          el.action = param.data.action
          el.rrParmSpecId = param.data.rrParmSpecId
          return el
        }else {
          return el
        }
      })
      this.editIndicators(indicator).then(() => {
        this.loadPage(this.$t('parameter-successfully-updated'))
      })
    }
  },

  mounted () {
    this.setRegionTypes([])
    this.setRegionSubtypes([])
    this.setGroups([])
    this.setYears([])
    this.years().then((res) => {
      let data = res.map((elem) => {
        elem.name = elem.id
        elem.value = elem.id
        return elem
      })
      this.loader = false;
      this.setYears(data)
    })
  }
}
</script>

<style lang="scss" scoped>
.final {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-height: 500px;

  @media (max-width: 1000px) {
    overflow-x: auto;
  }

  &__select {
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 20px;
    background: #FFFFFF;
    box-shadow: 2px 2px 10px rgba(153, 153, 153, 0.2);
    border-radius: 20px;
    max-width: 433px;
    width: 100%;

    ::v-deep .ui-select {
      z-index: auto;
      max-width: 305px;
    }
    ::v-deep .ui-select__label {
      display: none;
    }

    &-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;

      p {
        font-size: 16px;
        line-height: 19px;
        color: #1B1A1F;
        white-space: nowrap;
      }
    }
  }

  &__actions {
    button {
      max-width: 366px;
    }
  }
}
</style>