var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"final"},[_c('div',{staticClass:"final__select"},[_c('div',{staticClass:"final__select-item"},[_c('p',[_vm._v(_vm._s(_vm.$t('year')))]),_c('ui-select',{attrs:{"options":_vm.getYears},on:{"input":_vm.getTypes},model:{value:(_vm.year),callback:function ($$v) {_vm.year=$$v},expression:"year"}})],1),_c('div',{staticClass:"final__select-item"},[_c('p',[_vm._v(_vm._s(_vm.$t('type-2')))]),_c('ui-select',{attrs:{"options":_vm.getRegionTypes},on:{"input":_vm.getSubtypes},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})],1),_c('div',{staticClass:"final__select-item"},[_c('p',[_vm._v(_vm._s(_vm.$t('subtype')))]),_c('ui-select',{attrs:{"options":_vm.getRegionSubtypes},on:{"input":_vm.getRegionGroups},model:{value:(_vm.subtype),callback:function ($$v) {_vm.subtype=$$v},expression:"subtype"}})],1),_c('div',{staticClass:"final__select-item"},[_c('p',[_vm._v(_vm._s(_vm.$t('group')))]),_c('ui-select',{attrs:{"options":_vm.getGroups},on:{"input":_vm.getFinalIndicators},model:{value:(_vm.group),callback:function ($$v) {_vm.group=$$v},expression:"group"}})],1)]),_c('UiLoader',{model:{value:(_vm.loader),callback:function ($$v) {_vm.loader=$$v},expression:"loader"}}),_c('UiNotification',{attrs:{"message":_vm.message},model:{value:(_vm.showNotification),callback:function ($$v) {_vm.showNotification=$$v},expression:"showNotification"}}),((_vm.group.name && !_vm.loader && _vm.getIndicators.length) || _vm.isEmpty)?_c('Table',{ref:"table",attrs:{"title":_vm.$t('groups-of-indicators'),"descriptionList":[
          {title: _vm.$t('code'), width: 50},
          {title: _vm.$t('type-2'), width: 420},
          {title: _vm.$t('federal-district-russian-federation-calculation'), width: 97, paddingLeft: 0, textAlign: 'center'},
          {title: _vm.$t('indicator-weight'), width: 111, paddingLeft: 0, textAlign: 'center'},
          {title: _vm.$t('algorithm-calculation'), width: 120, paddingLeft: 0, textAlign: 'center'},
          {title: _vm.$t('inversion'), width: 100, paddingLeft: 0, textAlign: 'center'}
      ],"actionText":_vm.$t('add-line'),"data":_vm.getIndicators,"sort":true,"hideEditIcon":"","isFinal":""},on:{"addRowField":_vm.addFinalIndicators,"changeRowField":_vm.editFinalIndicators,"deleteRowField":_vm.deleteFinalIndicators}}):_vm._e(),((_vm.group.name && !_vm.loader && _vm.getIndicators.length) || _vm.isEmpty)?_vm._l((_vm.dataForParams),function(item,key){return _c('ParamsTable',{key:key,attrs:{"data":item,"options":_vm.paramsOptions},on:{"addNewParam":_vm.addNewParam,"editParam":_vm.editParam,"deleteParam":_vm.deleteParam,"deleteAllParams":_vm.deleteAllParams}})}):_vm._e(),(_vm.isNewParamsTable && !_vm.loader)?_c('ParamsTable',{attrs:{"is-new":"","addParamsTableOption":_vm.forNewParams,"options":_vm.paramsOptions},on:{"deleteNewParam":function($event){_vm.isNewParamsTable = false},"addNewParam":_vm.addNewParam}}):_vm._e(),_c('div',{staticClass:"final__actions"},[(_vm.group.name && !_vm.loader && !_vm.getIndicators.length && !_vm.isEmpty)?_c('ui-button',{attrs:{"color":"error"},on:{"click":_vm.addNewTable}},[_c('img',{attrs:{"src":require("@/assets/svg/icon-plus-white.svg"),"alt":""}}),_vm._v(" "+_vm._s(_vm.$t('new-table'))+" ")]):_vm._e()],1),_c('div',{staticClass:"final__actions"},[(_vm.group.name && !_vm.loader && _vm.getIndicators.length && _vm.forNewParams.length)?_c('ui-button',{attrs:{"color":"error"},on:{"click":_vm.addNewParams}},[_c('img',{attrs:{"src":require("@/assets/svg/icon-plus-white.svg"),"alt":""}}),_vm._v(" "+_vm._s(_vm.$t('add-parameters'))+" ")]):_vm._e()],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }