<template>
  <div class="params-table">
    <div class="params-table__title">
      <h2>{{ data?.title || addParamsTable?.name }}</h2>
    </div>
    <div class="params-table__body body">
      <ParamsTableRow
          v-for="(item, key) in data?.items"
          :key="key"
          :item="item"
          v-show="data.items.length"
          @deleteParam="$emit('deleteParam', {data: $event, itemId: addParamsTable.id || data.id})"
          @editParam="$emit('editParam', {data: $event, rowId: addParamsTable.id || data.id})"
          :showMath="key+1 === data.items.length && key !== 0 && !addNewActions"
          :options="options"
      />
      <ParamsTableNewRow
          v-if="addNewActions"
          :options="options"
          @addNewParam="$emit('addNewParam', {data: $event, itemId: addParamsTable.id || data.id})"
          @deleteNewParam="deleteNewParam"
          :ord="data?.items.length"
      />
      <div class="body__select" v-if="isNewParams">
        <UiSelect v-model="addParamsTable" :options="addParamsTableOption" label="Выберите код"/>
        <img src="@/assets/svg/icon-delete-admin.svg" alt="" @click="$emit('deleteNewParam')"/>
        <img src="@/assets/images/check-active.png" alt="" @click="addNewActions = true, isNewParams = false"/>
      </div>
    </div>
    <ui-button color="outline" class="params-table__action" @click="addNewActions = true" v-if="!isNewParams">
      <img src="../../../../assets/svg/icon-plus-red.svg" alt="">
      <p>{{ $t('add-line') }}</p>
    </ui-button>
    <UiAlert v-model="showAlert">
      <div class="ui-alert__title">{{ $t('do-you-really-want-to-delete') }}</div>
      <div class="ui-alert__action">
        <ui-button @click="deleteAllParams" color="error">{{ $t('delete') }}</ui-button>
        <ui-button @click="showAlert = false" color="white">{{ $t('cancel-2') }}</ui-button>
      </div>
    </UiAlert>
  </div>
</template>

<script>
import UiButton from "@/components/ui/UiButton.vue";
import ParamsTableRow from "@/components/template/admin/regionInformer/ParamsTableRow.vue";
import UiSelect from "@/components/ui/UiSelect.vue";
import ParamsTableNewRow from "@/components/template/admin/regionInformer/ParamsTableNewRow.vue";
import UiAlert from "@/components/ui/UiAlert.vue";
export default {
  name: "ParamsTable",
  components: {UiAlert, ParamsTableNewRow, UiSelect, ParamsTableRow, UiButton},
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    options: {
      type: Array,
      default: () => []
    },
    isNew: {
      type: Boolean,
      default: false
    },
    addParamsTableOption: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      addNewActions: false,
      addParamsTable: {},
      isNewParams: false,
      showAlert: false
    }
  },

  methods: {
    deleteAllParams () {
      this.$emit('deleteAllParams', this.data.id)
    },

    deleteNewParam () {
      this.addNewActions = false
      this.$emit('deleteNewParam')
    }
  },

  mounted () {
    this.isNewParams = this.isNew
    if (this.isNewParams) {
      this.addParamsTable = this.addParamsTableOption[0]
    }
  },
}
</script>

<style lang="scss" scoped>
.params-table {
  width: 100%;
  border-radius: 20px;
  background: #FFFFFF;
  box-shadow: 2px 2px 10px 0 rgba(153, 153, 153, 0.20);
  border: 1px solid transparent;
  transition: 0.3s;

  &__title {
    padding: 10px 20px;
    width: 100%;
    background: #E21F1F;
    border-radius: 20px 20px 0 0;
    height: 37px;
    min-width: 600px;
    display: flex;
    align-items: center;

    img {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }

    h2 {
      color: #FFFFFF;
      font-size: 14px;
      font-weight: 400;
    }
  }

  .body {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 30px 38px 0 20px;

    &__select {
      display: flex;
      align-items: center;
      gap: 5px;
      max-width: 368px;
      padding-bottom: 40px;

      img {
        margin-top: 20px;
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
    }
  }

  &__action {
    width: max-content !important;
    padding: 30px 20px !important;

    &:hover {
      text-decoration: none !important;
    }

    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #CE2121;
    }
  }
}
</style>